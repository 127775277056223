import { configureStore } from "@reduxjs/toolkit";

import riderReducer from "./riderReducer";
import registrationReducer from "./registrationReducer";
import cacheReducer from "./cacheReducer";

export const store = configureStore({
  reducer: {
    rider: riderReducer,
    registration: registrationReducer,
    cache: cacheReducer,
  },
});

export type tRootState = ReturnType<typeof store.getState>;
