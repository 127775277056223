import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { IonIcon } from "@ionic/react";
import {
  closeCircle,
  helpCircleOutline,
  paperPlaneOutline,
  readerOutline,
  receiptOutline,
  syncOutline,
  thumbsUpOutline,
  trendingDownOutline,
  trendingUpOutline,
  wallet,
} from "ionicons/icons";

import { tRootState } from "../../store";
import { tShipments } from "../../store/types/shipping.types";

import withAuth from "../../hoc/WithAuth/withAuth";

import useData from "../../hooks/useData/useData";

import Header from "../../components/Header/Header";
import ShipmentActions from "../../components/ShipmentActions/ShipmentActions";

import { assertNotNull, getDate, roundDP } from "../../utils/func";

const Dashboard = () => {
  const rider = useSelector((state: tRootState) => state.rider.rider);
  const overview = useSelector((state: tRootState) => state.cache.overview);

  assertNotNull(rider);
  assertNotNull(overview);

  const { fetchOverview, fetchProfile } = useData();

  const [shipments, setShipments] = useState<tShipments>(overview.shipments);

  useEffect(() => {
    setShipments(overview.shipments);
  }, [overview]);

  useEffect(() => {
    fetchOverview();
    fetchProfile();
  }, [fetchOverview, fetchProfile]);

  return (
    <>
      <Header />
      <section className="dashboard-header">
        <div className="dashboard-header__container container">
          <div className="dashboard-header__main">
            <h3 className="dashboard-header__heading">
              Welcome Back, {rider.FirstName} {rider.LastName}
            </h3>
            <p className="dashboard-header__text">
              This is your Overview Report
            </p>
          </div>
        </div>
      </section>
      <main className="main">
        <div className="container">
          <div className="dsection">
            <div className="dcard-main-grid">
              <div className="dcard dcard--success">
                <div className="dcard__main">
                  <p className="dcard__key">Total Earnings</p>
                  <p className="dcard__value">
                    &#8358;{roundDP(rider.TotalEarnings, 2)}
                  </p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={trendingDownOutline} />
                </div>
              </div>
              <div className="dcard dcard--danger">
                <div className="dcard__main">
                  <p className="dcard__key">Withdrawn Earnings</p>
                  <p className="dcard__value">
                    &#8358;{roundDP(rider.WithdrawnEarnings, 2)}
                  </p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={trendingUpOutline} />
                </div>
              </div>
              <div className="dcard dcard--info">
                <div className="dcard__main">
                  <p className="dcard__key">Available Earnings</p>
                  <p className="dcard__value">
                    &#8358;{roundDP(rider.AvailableEarnings, 2)}
                  </p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={wallet} />
                </div>
              </div>
            </div>
            <div className="info-cards">
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData["PRE-TRANSIT"]}
                  </p>
                  <p className="dcard__key text-uppercase">Pre-Transit</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={receiptOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.TRANSIT}
                  </p>
                  <p className="dcard__key text-uppercase">Transit</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={paperPlaneOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.DELIVERED}
                  </p>
                  <p className="dcard__key text-uppercase">Delivered</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={thumbsUpOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.FAILED}
                  </p>
                  <p className="dcard__key text-uppercase">Failed</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={closeCircle} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.CANCELED}
                  </p>
                  <p className="dcard__key text-uppercase">Canceled</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={closeCircle} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.RETURNED}
                  </p>
                  <p className="dcard__key text-uppercase">Returned</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={syncOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.UNKNOWN}
                  </p>
                  <p className="dcard__key text-uppercase">Unknown</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={helpCircleOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {Object.values(overview.analyticsData).reduce(
                      (a, b) => a + b,
                      0
                    )}
                  </p>
                  <p className="dcard__key text-uppercase">Total</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={readerOutline} />
                </div>
              </div>
            </div>
            <section>
              <div className="dheader">
                <h3 className="dheading">Recent Shipments</h3>
              </div>
              <div className="table-responsive shipments-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Description</th>
                      <th>Delivery</th>
                      <th>Date</th>
                      {/* <th>Carrier</th> */}
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!shipments.length ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No shipments
                        </td>
                      </tr>
                    ) : null}
                    {shipments.map((shipment) => (
                      <tr key={shipment._id}>
                        <td>#{shipment._id.slice(shipment._id.length - 6)}</td>
                        <td>{shipment.Description}</td>
                        <td>
                          <div>
                            <p>
                              <strong>Name:</strong> {shipment.Cosignee.Name}
                            </p>
                            <p>
                              <strong>Telephone:</strong>{" "}
                              {shipment.Cosignee.Telephone}
                            </p>
                            <p>
                              <strong>Address:</strong>{" "}
                              {shipment.Cosignee.Address}
                            </p>
                          </div>
                        </td>
                        <td>{getDate(shipment.PickupDate!)}</td>
                        {/* <td>{shipment.Carrier.Name}</td> */}
                        <td title={shipment.LastActivity}>
                          {shipment.Status}
                          <br />({shipment.LastActivity.slice(0, 30)}
                          {shipment.LastActivity.length > 30 ? "..." : ""})
                        </td>
                        <td>
                          <ShipmentActions
                            shipment={shipment}
                            setShipments={setShipments}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="shipments">
                {shipments.map((shipment) => (
                  <div className="shipment" key={shipment._id}>
                    <div className="shipment__header">
                      <p>
                        <strong>Shipment:</strong> #
                        {shipment._id.slice(shipment._id.length - 6)}
                      </p>
                      <div className="shipment__right">
                        <span title={shipment.LastActivity}>
                          {shipment.Status}
                        </span>
                        <ShipmentActions
                          setShipments={setShipments}
                          shipment={shipment}
                        />
                      </div>
                    </div>
                    <div className="shipment__body">
                      <div className="mb-small">
                        <p>
                          <strong>Description:</strong> {shipment.Description}
                        </p>
                        {shipment.PickupDate ? (
                          <p>
                            <strong>Pickup Date:</strong>{" "}
                            {getDate(shipment.PickupDate)}
                          </p>
                        ) : null}
                      </div>
                      <div>
                        <p>
                          <strong>Name:</strong> {shipment.Cosignee.Name}
                        </p>
                        <p>
                          <strong>Telephone:</strong>{" "}
                          {shipment.Cosignee.Telephone}
                        </p>
                        <p>
                          <strong>Address:</strong> {shipment.Cosignee.Address}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Dashboard);
