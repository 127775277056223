import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { IonIcon } from "@ionic/react";
import { chevronBack, chevronForward } from "ionicons/icons";

import { tRootState } from "../../../store";
import { updateTransportInfo } from "../../../store/registrationReducer";

import useAlert from "../../../hooks/useAlert/useAlert";
import useData from "../../../hooks/useData/useData";

import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    address,

    bvn,
    telephoneCountry,
    telephone,
    emailAddress,

    type: CHDType,
    description: CHDDescription,
    vin: CHDVin,
    color: CHDColor,
    licensePlate: CHDLicensePlate,
    licenseExpiryDate: CHDLicenseExpiryDate,
    insurancePolicyNumber: CHDInsurancePolicyNumber,
    insuranceCompany: CHDInsuranceCompany,
  } = useSelector((state: tRootState) => state.registration);
  const transportTypes = useSelector(
    (state: tRootState) => state.cache.transportTypes
  );

  const { fetchTransportTypes } = useData();

  const [message, set_message, clear_message] = useAlert();

  const [type, setType] = useState(CHDType);
  const [description, setDescription] = useState(CHDDescription);
  const [vin, setVIN] = useState(CHDVin);
  const [color, setColor] = useState(CHDColor);
  const [licensePlate, setLicensePlate] = useState(CHDLicensePlate);
  const [licenseExpiryDate, setLicenseExpiryDate] =
    useState(CHDLicenseExpiryDate);
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState(
    CHDInsurancePolicyNumber
  );
  const [insuranceCompany, setInsuranceCompany] = useState(CHDInsuranceCompany);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !type ||
      !description ||
      !vin ||
      !color ||
      !licensePlate ||
      !licenseExpiryDate ||
      !insurancePolicyNumber ||
      !insuranceCompany
    )
      return set_message("warning", "Fill in all fields");

    dispatch(
      updateTransportInfo({
        type,
        description,
        vin,
        color,
        licensePlate,
        licenseExpiryDate,
        insurancePolicyNumber,
        insuranceCompany,
      })
    );

    navigate("/register/login-details");
  };

  useEffect(() => {
    clear_message();
  }, [
    type,
    description,
    vin,
    color,
    licensePlate,
    licenseExpiryDate,
    insurancePolicyNumber,
    insuranceCompany,
    clear_message,
  ]);

  useEffect(() => {
    fetchTransportTypes();
  }, [fetchTransportTypes]);

  if (!firstName || !lastName || !address) return <Navigate to="/register" />;
  if (!bvn || !telephoneCountry || !telephone || !emailAddress)
    return <Navigate to="/register/contact-info" />;

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <h3 className="auth__brand">DMX Logistics</h3>
        </div>
        <div className="auth__btns">
          <Link to="/login" className="auth__btn">
            Login
          </Link>
          <Link to="#" className="auth__btn auth__btn--active">
            Create account
          </Link>
        </div>
        <div className="auth__header2">
          <div className="auth__header2-main">
            <h3 className="auth__heading">Transport Info?</h3>
            <p className="auth__sub-heading">
              Kindly fill in your vehicle information
            </p>
          </div>
          <div className="auth__header2-right">
            <p className="auth__header2-step">Step 3/7</p>
            <div className="auth__header2-step-navigate">
              <button onClick={() => navigate("/register/contact-info")}>
                <IonIcon icon={chevronBack} />
              </button>
              <button onClick={() => navigate("/register/login-details")}>
                <IonIcon icon={chevronForward} />
              </button>
            </div>
          </div>
        </div>
        <form className="auth__form" onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="form-group">
              <label>Type</label>
              <select
                className="form-select"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">-- Select Vehicle Type --</option>
                {transportTypes.map((transportType) => (
                  <option key={transportType._id} value={transportType._id}>
                    {transportType.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>VIN</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter vin"
                value={vin}
                onChange={(e) => setVIN(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Color</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>License Plate Number</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter license plate number"
                value={licensePlate}
                onChange={(e) => setLicensePlate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>License Expiry Date</label>
              <input
                type="date"
                className="form-input"
                placeholder="Enter color"
                value={licenseExpiryDate}
                onChange={(e) => setLicenseExpiryDate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Insurance Policy Number</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter insurance policy number"
                value={insurancePolicyNumber}
                onChange={(e) => setInsurancePolicyNumber(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Insurance Company</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter insurance company"
                value={insuranceCompany}
                onChange={(e) => setInsuranceCompany(e.target.value)}
              />
            </div>
          </div>
          <button className="button mt-small">Continue</button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default PersonalInfo;
