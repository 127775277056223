import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tCountry } from "./types/app.types";

import { Entries } from "../utils/utils.types";

type RegistrationType = {
  firstName: string;
  lastName: string;
  address: string;

  bvn: number | "";
  emailAddress: string;
  telephoneCountry: tCountry | null;
  telephone: number | "";

  type: string;
  description: string;
  vin: string;
  color: string;
  licensePlate: string;
  licenseExpiryDate: string;
  insurancePolicyNumber: string;
  insuranceCompany: string;
};

const RegistrationInitialState: RegistrationType = {
  firstName: "",
  lastName: "",
  address: "",

  bvn: "",
  emailAddress: "",
  telephoneCountry: null,
  telephone: "",

  type: "",
  description: "",
  vin: "",
  color: "",
  licensePlate: "",
  licenseExpiryDate: "",
  insurancePolicyNumber: "",
  insuranceCompany: "",
};

const registrationSlice = createSlice({
  name: "registration",
  initialState: getState<RegistrationType>(
    "registration",
    RegistrationInitialState
  ),
  reducers: {
    updatePersonalInfo(
      state,
      {
        payload,
      }: PayloadAction<{ firstName: string; lastName: string; address: string }>
    ) {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.address = payload.address;

      saveState("registration", current(state));
    },
    updateContactInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        bvn: number | "";
        emailAddress: string;
        telephoneCountry: tCountry;
        telephone: number | "";
      }>
    ) {
      state.bvn = payload.bvn;
      state.emailAddress = payload.emailAddress;
      state.telephoneCountry = payload.telephoneCountry;
      state.telephone = payload.telephone;

      saveState("registration", current(state));
    },
    updateTransportInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        type: string;
        description: string;
        vin: string;
        color: string;
        licensePlate: string;
        licenseExpiryDate: string;
        insurancePolicyNumber: string;
        insuranceCompany: string;
      }>
    ) {
      state.type = payload.type;
      state.description = payload.description;
      state.vin = payload.vin;
      state.color = payload.color;
      state.licensePlate = payload.licensePlate;
      state.licenseExpiryDate = payload.licenseExpiryDate;
      state.insurancePolicyNumber = payload.insurancePolicyNumber;
      state.insuranceCompany = payload.insuranceCompany;

      saveState("registration", current(state));
    },
    clearRegistration(state) {
      for (const [k, v] of Object.entries(
        RegistrationInitialState
      ) as Entries<RegistrationType>) {
        state[k] = v as any;
      }

      saveState("registration", current(state));
    },
  },
});

export const {
  updatePersonalInfo,
  updateContactInfo,
  updateTransportInfo,
  clearRegistration,
} = registrationSlice.actions;

export default registrationSlice.reducer;
